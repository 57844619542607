import React, { useState }  from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Section, Container, SectionTitle, SectionDescription, SectionImageBkg, BreadCrumb, ExploreMore } from "../components/Section"
import { Banner, BannerLeft, BannerRight, BannerTitle, BannerLead, BannerDesc,  BannerCard } from "../components/Banner"
import { PhoneIcon } from "../components/Icons"
import CategoryCarousel from "../components/CategoryCarousel"
import ColorPicker from "../components/ColorPicker"
import JoinForm from "../components/JoinForm"
import ProductCard from "../components/ProductCard"
import PrimaryButton from "../components/Button/PrimaryButton"
import CallUs from "../components/CallUs"

const SectionBuyMetal = styled.div`
  position:relative;
  border-bottom:1px solid rgba(112,112,112,0.18);
  margin-bottom:40px;
  padding-bottom:40px;
  p {
    color: #fff;
  }
  h2 {
    color: #fff;
  }
  @media(min-width:992px){
    margin-bottom:60px;
    padding-bottom:60px;
  }
`
const BannerArrow = styled.div`
  display:none;
  @media(min-width:992px){
    position: absolute;
    bottom: 20%;
    right: -22px;
    width: 0;
    height: 0;
    border-top: 34px solid #fff;
    border-right: 24px solid transparent;
    z-index: 1;
    display: inline-block;
  }
`
const GridMasonery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin:0 -10px;
  justify-content: space-evenly;
  align-items:center;
`
const GridItemMasonery = styled.div`
  position: relative;
  width: 100%;
  padding:0 10px;
  @media(min-width:992px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width:1200px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }  
  .card{
    .card-title{
      > span{
        @media(min-width:1440px){
          font-size: 18px;
        }
      }
    }
  }
`

const SectionMasoneryImage = styled(SectionImageBkg)`
  @media (min-width: 1200px) {
    background-color:#161420;
    width: 80%;
    height: 300px;
    border-radius:0 0 100px 0;
  }
`

const TxMetalBuildingsPage = ({ data , location}) => {
  const [showLimit, setShowLimit] = useState(12);
  const pageData = data.contentfulPageMetalBuildings
  const allCategories = data.allContentfulCategory.edges;
  const allProducts = data.allContentfulProduct.edges;
  return(
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#fff">
        <Banner className="banner">
          <BannerLeft>
            <BreadCrumb className="black"><Link to='/'>Home</Link><span>TX Metal Buildings</span></BreadCrumb>
            <BannerTitle><strong>TX</strong> <span>{pageData.heroTitle}</span></BannerTitle>
            <BannerLead>{pageData.heroSubTitle}</BannerLead>
            <BannerDesc>
              <div dangerouslySetInnerHTML={{__html: pageData.heroDescription.childMarkdownRemark.html}} />
            </BannerDesc>
            <ExploreMore mt="30px" justifyContent="left"><a href="tel:8067314816" aria-label="Phone Number"><PrimaryButton text="(806) 731-4816" iconAfter={<PhoneIcon />}/></a></ExploreMore>
            <BannerArrow />
          </BannerLeft>
          <BannerRight>
            <SectionImageBkg opacityImg="0.05"><StaticImage src="../images/category-bkg.jpg" alt="category-bkg" /></SectionImageBkg>
            <BannerCard className="card"><JoinForm location={location} /></BannerCard>
          </BannerRight>
        </Banner>
      </Section>

      <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="40px" pb="40px" bgColor="#fff" className="section-building">
        <SectionMasoneryImage opacityImg="0.5"><StaticImage src="../images/outline-building.png" alt="outline-building" /></SectionMasoneryImage>
        <Container maxWidth="1640px">
          <GridMasonery>
            {allProducts.map((item, i) => i < showLimit && (
              <GridItemMasonery className="item" key={i}>
                <ProductCard sku={item.node.skuNumber} data={item.node} />
              </GridItemMasonery>
            ))}
          </GridMasonery>
          {allProducts.length > showLimit && (
            <ExploreMore mt="30px"><button type="button" aria-label="button" onClick={()=>setShowLimit(showLimit + 6)}><PrimaryButton text="Load More" /></button></ExploreMore>
          )}
        </Container>
      </Section>
      <CallUs />

      <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="40px" pb="40px" bgColor="transparent" bgBefore="linear-gradient(to bottom,  #EAEFF7 0%,#F9FBFF 100%)" bgBeforeWidth="100%" bgBeforeHeight="100%" className="section-color">
        <Container>
          <SectionTitle textAlign="center"><span>{pageData.colorSectionTitlePrefix}</span> {pageData.colorSectionTitle}</SectionTitle>
          <SectionDescription textAlign="center">
            <div dangerouslySetInnerHTML={{__html: pageData.colorSectionDescription.childMarkdownRemark.html}} />
          </SectionDescription>
          <ColorPicker />
        </Container>
      </Section>
      
      <Section ept="120px" epb="120px" xpt="80px" xpb="80px" pt="40px" pb="40px" bgColor="#fff"  className="section-buy">
        <SectionImageBkg height="68%" bgColor="#161420" opacityImg="0.3"><StaticImage src="../images/black-vector.jpg" alt="black-vector" /></SectionImageBkg>
        <SectionBuyMetal>
          <Container>
            <div dangerouslySetInnerHTML={{__html: pageData.content.childMarkdownRemark.html}} />
          </Container>
        </SectionBuyMetal>
        <CategoryCarousel data={allCategories} current="all" />
      </Section>      
    </Layout>
  )

}

export default TxMetalBuildingsPage

export const pageQuery = graphql`
  query TxMetalBuildignsPageQuery {
    contentfulPageMetalBuildings {
      metaTitle
      metaDescription
      heroTitle
      heroSubTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      colorSectionTitlePrefix
      colorSectionTitle
      colorSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulCategory {
      edges {
        node {
          name
          url
          mainImage {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
    allContentfulProduct(sort: {order: ASC, fields: skuNumber}) {
      edges {
        node {
          name
          skuNumber
          image {
            gatsbyImageData(placeholder: BLURRED, quality: 90)
          }
        }
      }
    }
  }
`